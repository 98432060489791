//Theme Base Colour
$theme-color: 'yellow';

// Colours (All themes - but used in this settings file so need them here)
$color-verylightgrey: 			#fafafa; // Tile bg color
$color-brownish-grey: 			#717067; // text default			
$color-greyish-brown:			#3d3935; //sidebar text
$color-white: 					#fff; 

// Colours (maternity specific)
$color-community-yellow: 		#fcb31f; //button purple, primary
$color-community-orange:		#e09500; //tiles arrow bg AKA heading colour
// $color-lightpink: 				#f087a6; //banner light
// $color-darkpink: 				#a182bc; //banner dark 
// $color-otherpink:				#c785b2; //sidebar background
$color-community-lightyellow: 	#ffd691; //stats text
$color-community-darkorange:    #c58d17; // mobile expanded menu color

$color-primary: $color-community-yellow; 
$color-secondary: $color-community-orange; 
$color-tertiary: $color-community-orange;

$selection-color: $color-primary;
$selection-text-color: #fff;

//typography
$font-color: $color-brownish-grey;
$heading-color: $color-secondary;
$highlight-color: $selection-color;
$highlight-text-color: $selection-text-color;
$link-color: $color-primary;
$link-color-hover: $color-secondary;

//backgrounds
$color-pagination-bg: $color-primary;
$color-sidebar-bg: $color-primary;

//gradients
$gradient-start: $color-primary;
$gradient-end: $color-secondary;
$gradient-hr-bg: linear-gradient(to right, $gradient-start, $gradient-end);

//block specific
$color-stats: $color-community-lightyellow;
$color-tile-arrowbg: $color-secondary;
$stat-counter-bg-image: url('/public/img/jpg/counters-mental-bg@2x.jpg');
$stat-counter-bg-position-desktop: 100% 50%;

$color-search-bar: $color-primary;

$sidebar-triangle-background: url('/public/img/png/ic/triangle-community@3x.png');

//Mobile
// --Menu
$mobile-menu-toggle-arrow-bg-image: url('/public/img/svg/ic/expand-community.svg'); 
$mobile-menu-toggler-bg: $color-primary;
//lvl1
$mobile-menu-lvl1-bg: $color-primary;
$mobile-menu-lvl1-bg-active: $color-community-darkorange; 
//lvl2
$mobile-menu-item-lvl2: $color-greyish-brown;
//lvl3
$mobile-menu-item-lvl3: $color-greyish-brown;
$mobile-menu-toggler-bg-expanded: $color-community-darkorange;

// Sidebar
$color-sidebarlink: $color-greyish-brown;
$opacity-sidebar-link-primary: 1;
$opacity-sidebar-link-secondary: 1;
$mobile-subnav-dropdown-bg: $color-primary;
$color-mobile-sidebar-toggle-color: $color-white;
$color-mobile-sidebar-toggle-color-hover: $color-mobile-sidebar-toggle-color;
$mobile-subnav-dropdown-bg-expanded: $color-community-darkorange;

// desktop menu
$desktop-menu-activeandhover: $color-secondary;